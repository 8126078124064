import axios, { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ADMIN_API_URL } from "../../../utils/env";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { IOrderbookState } from "../../../interfaces/aevo";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export const useOtc = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const createQuote = useCallback(
    async (
      otc_id: string,
      account: string,
      premium: string,
      otc_quote_price: string
    ): Promise<IOrderbookState | undefined> => {
      try {
        if (tokenId) {
          const response = await axios.post(
            ADMIN_API_URL(AdminEndpointEnum.OTC_CREATE_QUOTE),
            {
              otc_id,
              account,
              premium,
              otc_quote_price,
            },
            {
              headers: { Authorization: `Bearer ${tokenId}` },
            }
          );
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Create Quote Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, tokenId]
  );

  const confirmTrade = useCallback(
    async (
      otc_id: string,
      account: string
    ): Promise<IOrderbookState | undefined> => {
      try {
        if (tokenId) {
          const response = await axios.post(
            ADMIN_API_URL(AdminEndpointEnum.OTC_CONFIRM_TRADE),
            {
              otc_id,
              account,
            },
            {
              headers: { Authorization: `Bearer ${tokenId}` },
            }
          );
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Create Quote Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, tokenId]
  );

  return {
    createQuote,
    confirmTrade,
  };
};

import { useCallback, useState } from "react";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { GlobalFilterWrapper } from "../Table/style";
import { DetailsWrapper, FilterContainer } from "./style";
import { COMPONENTS } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";
import { useOtc } from "../../hooks/api/admin/useOtc";

export type BidAsk = {
  price: string;
  amount: string;
  isBid: boolean;
};

export function CreateQuote() {
  const { createQuote } = useOtc();
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<string>();
  const [id, setId] = useState<string>();
  const [premium, setPremium] = useState<string>();
  const [otcQuotePrice, setOtcQuotePrice] = useState<string>();

  const onCreateQuote = useCallback(async () => {
    if (!account || !id || !premium || !otcQuotePrice) {
      return;
    }
    setLoading(true);
    try {
      await createQuote(id, account, premium, otcQuotePrice);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [account, createQuote, id, otcQuotePrice, premium]);

  return (
    <DetailsWrapper>
      <FilterContainer>
        <GlobalFilterWrapper>
          <input
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            placeholder="Account"
          />
          <input
            value={id}
            onChange={(e) => setId(e.target.value)}
            placeholder="ID"
          />
          <input
            value={premium}
            onChange={(e) => setPremium(e.target.value)}
            placeholder="Premium"
          />
          <input
            value={otcQuotePrice}
            onChange={(e) => setOtcQuotePrice(e.target.value)}
            placeholder="OTC Quote Price"
          />
        </GlobalFilterWrapper>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => onCreateQuote()}
          style={{
            minHeight: `${COMPONENTS.selectHeight}px`,
            maxHeight: `${COMPONENTS.selectHeight}px`,
          }}
          disabled={!account || !id || !premium || !otcQuotePrice}
        >
          {loading ? <Spinner /> : "Create Quote"}
        </Button>
      </FilterContainer>
    </DetailsWrapper>
  );
}

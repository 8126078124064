import { useMemo, useState } from "react";
import { ISelectOption, Select } from "../shared/Select";
import {
  ContentWrapper,
  DebugWrapper,
  OptionsWrapper,
  Padding,
  SelectWrapper,
} from "./style";
import { CreateQuote } from "./CreateQuote";
import { ConfirmTrade } from "./ConfirmTrade";

enum OtcEnum {
  CREATE_QUOTE,
  CONFIRM_TRADE,
}

export function Otc() {
  const [otcOption, setOtcOption] = useState<OtcEnum>(OtcEnum.CREATE_QUOTE);

  const options: ISelectOption[] = useMemo(
    () => [
      {
        label: "Create Quote",
        isActive: otcOption === OtcEnum.CREATE_QUOTE,
        onClick: () => setOtcOption(OtcEnum.CREATE_QUOTE),
      },
      {
        label: "Confirm Trade",
        isActive: otcOption === OtcEnum.CONFIRM_TRADE,
        onClick: () => setOtcOption(OtcEnum.CONFIRM_TRADE),
      },
    ],
    [otcOption]
  );

  const pageContent = useMemo(() => {
    if (otcOption === OtcEnum.CREATE_QUOTE) {
      return <CreateQuote />;
    }

    return <ConfirmTrade />;
  }, [otcOption]);

  return (
    <DebugWrapper>
      <Padding>
        <OptionsWrapper>
          <SelectWrapper>
            <Select options={options} />
          </SelectWrapper>
        </OptionsWrapper>
        <ContentWrapper>{pageContent}</ContentWrapper>
      </Padding>
    </DebugWrapper>
  );
}

/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import currency from "currency.js";
import { IAccount, IAdl } from "../../../interfaces/aevo";
import {
  EditWrapper,
  FlagWrapper,
  LinkText,
  MarginWrapper,
  ReferralConfirmationWrapper,
} from "../style";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { ToastEnum, ToastStatusEnum, useToast } from "../../../hooks/toast";
import { ReactComponent as Copy } from "../../../assets/svg/copy.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";
import { ReactComponent as Check } from "../../../assets/svg/check.svg";
import { useAccount } from "../../../hooks/api/admin/useAccount";
import Toggle from "../../shared/Toggle";
import ConfirmationModal from "../../shared/ConfirmationModal";
import { shortenAddress } from "../../../utils/strings";
import { IKeyValue, KeyValue } from ".";
import { KeyValueContainer, StatTitle } from "./style";
import { DataContext } from "../../../context/DataContext";
import { TextWrapper, CopyButton } from "../../Table/style";
import { Button, ButtonThemeEnum } from "../../shared/Buttons/styles";
import {
  BalanceInfo,
  Collateral,
  CollateralWrapper,
  RequestWrapper,
} from "../../Withdrawals/style";
import { useManualModeAccounts } from "../../../hooks/api/admin/useManualModeAccounts";
import { Spinner } from "../../shared/Spinner";
import { getCollateralLogo } from "../../../utils/asset";
import { SPACING } from "../../../constants/design/spacing";
import { useReferral } from "../../../hooks/api/admin/useReferral";
import { useQueryAccounts } from "../../../hooks/api/admin/useQueryAccounts";
import { QueryTypeEnum } from "../../Dropdown/DashboardFilterDropdown";

interface IAccountStatsProps {
  data?: IAccount;
  isMobile?: boolean;
}

export function AccountStats({ data, isMobile }: IAccountStatsProps) {
  const { accounts, records } = useContext(DataContext);
  const { addToast } = useToast();
  const { queryAccount } = useQueryAccounts();
  const { setManualMode } = useManualModeAccounts();
  const { createReferral } = useReferral();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isEditRefund, setEditRefund] = useState<boolean>(false);
  const [isEditReferralBonus, setEditReferralBonus] = useState<boolean>(false);
  const [isEditRefereeDiscount, setEditRefereeDiscount] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>(data?.username || "");
  const [referralBonus, setReferralBonus] = useState<number>();
  const [refereeDiscount, setRefereeDiscount] = useState<number>();
  const [isValidUsername, setIsValidUsername] = useState<boolean>(true);
  const [refundAmount, setRefundAmount] = useState<string>("");
  const [isValidGroup, setIsValidGroup] = useState<boolean>(true);
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);
  const [showReferralModal, setShowReferralModal] = useState<boolean>(false);
  const [group, setGroup] = useState<string>(data?.user_group || "");
  const {
    updateUsername,
    refundAccount,
    mutate,
    updatePortfolioMargin,
    updateReferralBonus,
    updateRefereeDiscount,
    updateUserGroup,
  } = useAccount();
  const [referralReferrer, setReferralReferrer] = useState<string>("");
  const [referralReferee, setReferralReferee] = useState<string>(
    data?.account || ""
  );
  const [referralRefereeDiscount, setReferralRefereeDiscount] =
    useState<number>(0.1);
  const [referralReferralBonus, setReferralReferralBonus] =
    useState<number>(0.1);
  const [showPMModal, setShowPMModal] = useState<boolean>(false);

  const transfersWarning =
    "(Only click the green submission tick once, and wait for a toast success. If it fails or nothing happens, reach out to an engineer)";

  useEffect(() => {
    if (isEdit === false) setUsername(data?.username || "");
  }, [data?.username, isEdit]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = event.target.value;
    setIsValidUsername(!newUsername.includes(" "));
    setUsername(newUsername);
  }, []);

  const onChangeGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newGroup = event.target.value;
      setIsValidGroup(!newGroup.includes(" "));
      setGroup(newGroup);
    },
    []
  );

  const onChangeRefund = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRefund = event.target.value;
      setRefundAmount(newRefund);
    },
    []
  );

  const onChangeReferralBonus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newReferralBonus = event.target.value;
      setReferralBonus(Number(newReferralBonus));
    },
    []
  );

  const onChangeRefereeDiscount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRefereeDiscount = event.target.value;
      console.log(newRefereeDiscount);
      setRefereeDiscount(Number(newRefereeDiscount));
    },
    []
  );

  const onSave = useCallback(async () => {
    if (isValidUsername) {
      setLoading(true);
      await updateUsername(username).finally(() => {
        mutate();
        setLoading(false);
        setEdit(false);
      });
    } else {
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Username should not contain spaces",
          status: ToastStatusEnum.ERROR,
        },
        4000
      );
    }
  }, [isValidUsername, updateUsername, username, mutate, addToast]);

  const onSaveGroup = useCallback(async () => {
    if (isValidGroup) {
      setLoading(true);
      await updateUserGroup(group).finally(() => {
        mutate();
        setLoading(false);
        setEdit(false);
        setIsValidGroup(true);
      });
    } else {
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Group should not contain spaces",
          status: ToastStatusEnum.ERROR,
        },
        4000
      );
    }
  }, [isValidGroup, updateUserGroup, group, mutate, addToast]);

  const onSaveRefund = useCallback(async () => {
    setLoading(true);
    if (refundAmount) {
      await refundAccount(refundAmount).finally(() => {
        mutate();
        setLoading(false);
        setEditRefund(false);
        setRefundAmount("");
      });
    }
  }, [refundAmount, refundAccount, mutate]);

  const onSaveReferralBonus = useCallback(async () => {
    setLoading(true);
    if (referralBonus) {
      await updateReferralBonus(referralBonus).finally(() => {
        mutate();
        setLoading(false);
        setEditReferralBonus(false);
      });
    }
  }, [mutate, referralBonus, updateReferralBonus]);

  const onSaveRefereeDiscount = useCallback(async () => {
    setLoading(true);
    if (refereeDiscount !== undefined) {
      await updateRefereeDiscount(refereeDiscount).finally(() => {
        mutate();
        setLoading(false);
        setEditRefereeDiscount(false);
      });
    }
  }, [mutate, refereeDiscount, updateRefereeDiscount]);

  const onPM = useCallback(
    async (isEnabled: boolean) => {
      setLoading(true);
      await updatePortfolioMargin(isEnabled).finally(() => {
        mutate();
        setLoading(false);
        setShowPMModal(false);
      });
    },
    [mutate, updatePortfolioMargin]
  );

  const onFlag = useCallback(() => {
    setShowFlagModal(true);
  }, []);
  const onCopy = useCallback(
    (address: string) => {
      navigator.clipboard.writeText(address);
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Address Copied",
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    },
    [addToast]
  );

  const getAirtableTag = useCallback(
    (address: string) => records[address] || undefined,
    [records]
  );

  const referrerDetails = useCallback(
    (account: string) => {
      const referrer: IAccount | undefined = accounts.find(
        (acc) => acc.account === account
      );

      if (referrer?.account) {
        return getAirtableTag(referrer?.account);
      }

      return shortenAddress(account);
    },
    [accounts, getAirtableTag]
  );

  const [referrerAddress, setReferrerAddress] = useState<string>();
  useEffect(() => {
    const fetchReferrerData = async () => {
      if (data?.referrer) {
        const queryAccounts = await queryAccount(
          QueryTypeEnum.USERNAME,
          data.referrer
        );
        if (queryAccounts?.length === 1) {
          setReferrerAddress(queryAccounts[0].account);
        }
      }
    };

    fetchReferrerData();
  }, [data?.referrer, queryAccount, referrerAddress]);

  const stats: IKeyValue[] = useMemo(
    () => [
      {
        title: "Airtable Tag",
        value: (
          <TextWrapper>
            <div>
              {data?.account && getAirtableTag(data?.account)
                ? getAirtableTag(data?.account)
                : "-"}
            </div>
          </TextWrapper>
        ),
      },
      {
        title: "Account Address",
        value: (
          <TextWrapper>
            <div>{shortenAddress(data?.account!)}</div>
            <CopyButton
              onClick={(e) => {
                e.stopPropagation();
                onCopy(data?.account || "");
              }}
              style={{ padding: 0 }}
            >
              <Copy />
            </CopyButton>
          </TextWrapper>
        ),
      },
      {
        title: "Username",
        value: isEdit ? (
          <EditWrapper>
            <input value={username} onChange={onChange} />
            <Check onClick={() => onSave()} />
            <Cross onClick={() => setEdit(false)} />
          </EditWrapper>
        ) : (
          <TextWrapper>
            <span>{data?.username}</span> <Edit onClick={() => setEdit(true)} />
          </TextWrapper>
        ),
      },
      {
        title: "Portfolio Margin",
        value: (
          <MarginWrapper>
            <Toggle
              isOn={Boolean(data?.is_portfolio)}
              onToggle={() => setShowPMModal(true)}
            />
          </MarginWrapper>
        ),
      },
      {
        title: "Account Type",
        value: data?.account_type.toString().toUpperCase(),
      },
      {
        title: "Referrer",
        value: data?.referrer ? (
          <LinkText
            to={data?.referrer ? `/account/${referrerAddress}` : ""}
            style={{
              color: TEXT_COLORS.one,
            }}
            target="_blank"
          >
            {data?.referrer ? referrerDetails(data?.referrer!) : "-"}
          </LinkText>
        ) : (
          <TextWrapper>
            <span>-</span> <Edit onClick={() => setShowReferralModal(true)} />
          </TextWrapper>
        ),
      },
      {
        title: "Email",
        value: data?.email_address || "-",
      },
      {
        title: "Permissions",
        value: data?.permissions.length ? data.permissions.join("\n") : "-",
      },
      {
        title: "Referral Bonus",
        value: isEditReferralBonus ? (
          <EditWrapper>
            <input
              type="number"
              value={referralBonus}
              onChange={onChangeReferralBonus}
            />
            <Check onClick={() => onSaveReferralBonus()} />
            <Cross
              onClick={() => {
                setEditReferralBonus(false);
                setReferralBonus(Number(data?.referral_bonus || "0"));
              }}
            />
          </EditWrapper>
        ) : (
          <TextWrapper>
            <span>{data?.referral_bonus}</span>{" "}
            <Edit onClick={() => setEditReferralBonus(true)} />
          </TextWrapper>
        ),
      },
      {
        title: "Referee Discount",
        value: isEditRefereeDiscount ? (
          <EditWrapper>
            <input
              type="number"
              value={refereeDiscount}
              onChange={onChangeRefereeDiscount}
            />
            <Check onClick={() => onSaveRefereeDiscount()} />
            <Cross
              onClick={() => {
                setEditRefereeDiscount(false);
                setRefereeDiscount(Number(data?.referee_discount || "0"));
              }}
            />
          </EditWrapper>
        ) : (
          <TextWrapper>
            <span>{data?.referee_discount}</span>{" "}
            <Edit onClick={() => setEditRefereeDiscount(true)} />
          </TextWrapper>
        ),
      },
      {
        title: "User Group",
        value: isEdit ? (
          <EditWrapper>
            <input value={group} onChange={onChangeGroup} />
            <Check onClick={() => onSaveGroup()} />
            <Cross onClick={() => setEdit(false)} />
          </EditWrapper>
        ) : (
          <TextWrapper>
            <span>{data?.user_group || ""}</span>{" "}
            <Edit onClick={() => setEdit(true)} />
          </TextWrapper>
        ),
      },
      {
        title: "In Liquidation",
        value: data?.in_liquidation ? "Yes" : "No",
      },
      {
        title: "In ADL",
        value: data?.in_adl.length
          ? data.in_adl.map((adl: IAdl) => adl.instrument_name).join(", ")
          : "No",
      },
      {
        title: "Flag Account",
        value: (
          <FlagWrapper>
            <Button
              buttonTheme={ButtonThemeEnum.HIGHLIGHT}
              style={{ maxHeight: 20, minHeight: 20, fontSize: 11 }}
              onClick={() => onFlag()}
            >
              Flag
            </Button>
          </FlagWrapper>
        ),
      },
    ],
    [
      data?.account,
      data?.account_type,
      data?.email_address,
      data?.in_liquidation,
      data?.in_adl,
      data?.is_portfolio,
      data?.permissions,
      data?.referee_discount,
      data?.referral_bonus,
      data?.referrer,
      data?.user_group,
      data?.username,
      getAirtableTag,
      group,
      isEdit,
      isEditRefereeDiscount,
      isEditReferralBonus,
      onChange,
      onChangeGroup,
      onChangeRefereeDiscount,
      onChangeReferralBonus,
      onCopy,
      onFlag,
      onSave,
      onSaveGroup,
      onSaveRefereeDiscount,
      onSaveReferralBonus,
      refereeDiscount,
      referralBonus,
      referrerAddress,
      referrerDetails,
      username,
    ]
  );

  const equityStats: IKeyValue[] = useMemo(
    () => [
      {
        title: "Equity",
        value: currency(data?.equity!).format(),
      },
      {
        title: "Available Balance",
        value: currency(data?.available_balance!).format(),
      },
      {
        title: "Available Margin",
        value: currency(data?.available_margin!).format(),
      },
      {
        title: "Credits",
        value: currency(data?.credit!).format(),
      },
      {
        title: `Refund Account ${transfersWarning}`,
        value: isEditRefund ? (
          <EditWrapper>
            <input
              type="number"
              value={refundAmount}
              onChange={onChangeRefund}
            />
            <Check onClick={() => onSaveRefund()} />
            <Cross
              onClick={() => {
                setEditRefund(false);
                setRefundAmount("");
              }}
            />
          </EditWrapper>
        ) : (
          <TextWrapper>
            <span />
            <Edit onClick={() => setEditRefund(true)} />
          </TextWrapper>
        ),
      },
    ],
    [
      data?.available_balance,
      data?.available_margin,
      data?.credit,
      data?.equity,
      isEditRefund,
      onChangeRefund,
      onSaveRefund,
      refundAmount,
    ]
  );

  if (!data?.account) return null;
  return (
    <div>
      <ConfirmationModal
        isLoading={isLoading}
        title={"Change Margin Type"}
        show={showPMModal}
        onHide={() => setShowPMModal(false)}
        style={{ width: "311px" }}
        extras={<div>Are you sure you want to change the margin type?</div>}
        primaryColor="blue"
        confirmationButton={{
          title: `Switch to ${
            data?.is_portfolio ? "Standard" : "Portfolio"
          } Margin`,
          onClick: () => onPM(!data?.is_portfolio),
          disabled: isLoading,
        }}
      />
      <ConfirmationModal
        width={"200px"}
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Flag Account"}
        extras={
          <RequestWrapper>
            Are you sure you want to flag{" "}
            <div
              style={{
                color: records[data?.account!]
                  ? COLORS.blue.one
                  : TEXT_COLORS.one,
              }}
            >
              {records[data?.account!]
                ? records[data?.account!]
                : data?.account!}
            </div>
            ?
          </RequestWrapper>
        }
        show={showFlagModal}
        onHide={() => setShowFlagModal(false)}
        confirmationButton={{
          title: "Flag",
          onClick: async () => {
            if (data?.account) {
              setManualMode(data?.account, true).finally(() => {
                setShowFlagModal(false);
              });
            }
          },
          disabled: isLoading || !data?.account,
        }}
      />
      <ConfirmationModal
        width={"200px"}
        primaryColor={COLORS.blue.one}
        secondaryColor={COLORS.blue.two}
        tertiaryColor={COLORS.blue.three}
        title={"Create Referral"}
        extras={
          <ReferralConfirmationWrapper>
            <div>
              Referrer:{" "}
              <input
                value={referralReferrer}
                onChange={(e) => setReferralReferrer(e.target.value)}
              />
            </div>
            <div>
              Referee:{" "}
              <input
                value={referralReferee}
                onChange={(e) => setReferralReferee(e.target.value)}
              />
            </div>
            <div>
              ReferralBonus:{" "}
              <input
                value={referralReferralBonus}
                onChange={(e) =>
                  setReferralReferralBonus(Number(e.target.value))
                }
              />
            </div>
            <div>
              RefereeDiscount:{" "}
              <input
                value={referralRefereeDiscount}
                onChange={(e) =>
                  setReferralRefereeDiscount(Number(e.target.value))
                }
              />
            </div>
          </ReferralConfirmationWrapper>
        }
        show={showReferralModal}
        onHide={() => setShowReferralModal(false)}
        confirmationButton={{
          title: "Create",
          onClick: async () => {
            if (data?.account) {
              createReferral(
                referralReferrer,
                referralReferee,
                referralReferralBonus,
                referralRefereeDiscount
              ).finally(() => {
                setShowReferralModal(false);
              });
            }
          },
          disabled:
            !data?.account ||
            !referralReferrer ||
            !referralReferee ||
            !referralReferralBonus ||
            !referralRefereeDiscount,
        }}
      />
      <StatTitle isMobile={isMobile}>Account</StatTitle>
      <KeyValueContainer isMobile={isMobile}>
        {stats.map((stat, i) => (
          <KeyValue key={i} title={stat.title} value={stat.value} />
        ))}
      </KeyValueContainer>
      <StatTitle isMobile={isMobile}>Asset Breakdown</StatTitle>
      <div style={{ margin: isMobile ? 0 : SPACING.two }}>
        <BalanceInfo style={{ padding: `0 ${SPACING.one}px` }}>
          <div>
            <span />
            Balance
          </div>
          <div>
            <span />
            Available Balance
          </div>
          <div>
            <span />
            USD Denom. Balance
          </div>
        </BalanceInfo>
        {isLoading ? (
          <Spinner />
        ) : data && data.account ? (
          <CollateralWrapper>
            {data.collaterals.map((c) => (
              <div key={c.collateral_asset}>
                <Collateral>
                  <span>
                    {currency(Number(c.balance)).format({
                      symbol: "",
                    })}{" "}
                    {c.collateral_asset}{" "}
                  </span>
                  <span style={{ color: COLORS.orange.one }}>
                    {currency(Number(c.available_balance)).format({
                      symbol: "",
                    })}{" "}
                    {c.collateral_asset}{" "}
                  </span>
                  <span style={{ color: COLORS.blue.one }}>
                    {currency(c.collateral_value).format()}
                  </span>
                </Collateral>
                <img
                  src={getCollateralLogo(c.collateral_asset)}
                  alt={c.collateral_asset}
                />
              </div>
            ))}
          </CollateralWrapper>
        ) : (
          "-"
        )}
      </div>
      <StatTitle isMobile={isMobile}>Equity</StatTitle>
      <KeyValueContainer isMobile={isMobile}>
        {equityStats.map((stat, i) => (
          <KeyValue key={i} title={stat.title} value={stat.value} />
        ))}
      </KeyValueContainer>
    </div>
  );
}

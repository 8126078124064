import currency from "currency.js";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { IAevoPosition, IAevoRiskPosition } from "../../../interfaces/aevo";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import {
  Align,
  LeftTitleWrapper,
  RightTitleWrapper,
  TableHeaderCell,
  TableLoader,
  TableWrapper,
  TitleWrapper,
} from "../../Table/style";
import DownloadCSVButton from "../../shared/DownloadCSVButton";
import { IAirtableRecord } from "../../../hooks/api/admin/useAirtableTags";

interface IPositionTableProps {
  positions: IAevoRiskPosition[];
  records: IAirtableRecord;
  selectedAsset: string | undefined;
  isLoading?: boolean;
}

export function RiskPositionTable({ positions, records, isLoading }: IPositionTableProps) {
  const getAirtableTag = useCallback((address: string) => records[address] || undefined, [records]);

  const columns: ITableColumn<IAevoRiskPosition>[] = useMemo(
    () => [
      {
        title: "Account",
        align: "left",
        accessor: "account",
        Cell: ({ value }) => (
          <Link
            to={`/account/${value}`}
            style={{
              color: TEXT_COLORS.one,
              textDecoration: "none",
            }}
          >
            {getAirtableTag(value) ?? value}
          </Link>
        ),
      },
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Side",
        align: "left",
        accessor: "side",
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "buy" ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {value === "buy" ? "Long" : "Short"}
          </div>
        ),
      },
      {
        title: "Contracts",
        align: "right",
        id: "contracts",
        accessor: "amount",
        valueExtractor: (value) => `${currency(value).format({ symbol: "" })}`,
      },
      {
        title: "Notional",
        align: "right",
        id: "notional",
        accessor: "notional_value",
        valueExtractor: (value) => `$${Number(value).toLocaleString()}`,
        sortType: (a: any, b: any) => (Number(a) > Number(b) ? 1 : -1),
      },
      {
        title: "Entry Price",
        align: "right",
        accessor: "avg_entry_price",
        valueExtractor: (value) => `$${Number(value).toPrecision(6)}`,
      },
      {
        title: "Mark Price",
        align: "right",
        accessor: "mark_price",
        valueExtractor: (value) => `$${Number(value).toPrecision(6)}`,
      },
      {
        title: "Unrealized PnL",
        align: "right",
        accessor: "unrealized_pnl",
        Cell: ({ value }: any) => (
          <Align
            align="right"
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                parseFloat(value) > 0 ? COLORS.green.one : parseFloat(value) < 0 ? COLORS.red.one : TEXT_COLORS.one,
            }}
          >
            {currency(value).format()}
          </Align>
        ),
      },
      {
        title: "% of OI",
        align: "right",
        accessor: "percent_of_open_interest",
        valueExtractor: (value) => `${(Number(value)*100).toPrecision(2)}%`,
        sortType: (a: any, b: any) => (Number(a) > Number(b) ? 1 : -1),
      },
    ],
    [getAirtableTag]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: positions,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
      expandSubRows: true,
    },
    useSortBy
  );

  const csvData = useMemo(() => {
    if (positions && positions.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        positions.map(
          (v: IAevoPosition) => [
            v.instrument_name,
            v.side,
            v.amount,
            v.avg_entry_price,
            v.mark_price,
            v?.option?.delta ? `${parseFloat(String(v?.option?.delta)).toFixed(4)}` : "-",
            v?.option?.gamma ? `${parseFloat(String(v?.option?.gamma)).toFixed(4)}` : "-",
            v?.option?.vega ? `${parseFloat(String(v?.option?.vega)).toFixed(4)}` : "-",
            v?.option?.theta ? `${parseFloat(String(v?.option?.theta)).toFixed(4)}` : "-",
            currency(Number(v.unrealized_pnl)).format(),
            currency(Number(v.maintenance_margin)).format(),
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, positions]);

  return (
    <div>
      <TitleWrapper>
        <LeftTitleWrapper>
          <h3>Aevo Positions {positions.length ? `(${positions.length})` : "(None)"}</h3>
        </LeftTitleWrapper>
        <RightTitleWrapper>
          <DownloadCSVButton data={csvData} filename={`Aevo Positions ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`} />
        </RightTitleWrapper>
      </TitleWrapper>

      <TableWrapper>
        <TableLoader isLoading={isLoading} />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
}
